import React from "react";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./TextComponent.scss";
var parse = require("html-react-parser");

class TextComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      subtitle: this.props.subtitle,
      image: this.props.image
    };
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);

    gsap.set([this.title, this.text1, this.text2], {
      y: 50,
      opacity: 0
    });
    gsap.to(this.title, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.text1, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.6,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.text2, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.8,
      duration: 1,
      ease: "power3"
    });
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="textComponent">
        <div className="textComponent_cont">
          <h2 ref={(ref) => (this.title = ref)}>{intl.formatMessage({ id: "About.WhoIsBehind.Title" })}</h2>
          <p ref={(ref) => (this.text1 = ref)}>{parse(intl.formatMessage({ id: "About.WhoIsBehind.Text1" }))}</p>
          <p ref={(ref) => (this.text2 = ref)}>{parse(intl.formatMessage({ id: "About.WhoIsBehind.Text2" }))}</p>
        </div>
      </div>
    );
  }
}
export default injectIntl(TextComponent);

import React from "react";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./WorkWithUsComponent.scss";
import img1 from "../../../images/About/colleagues/img1.png";
import img2 from "../../../images/About/colleagues/img2.png";
import img3 from "../../../images/About/colleagues/img3.png";
import img4 from "../../../images/About/colleagues/img4.png";
import img5 from "../../../images/About/colleagues/img5.png";
import img6 from "../../../images/About/colleagues/img6.png";
import img7 from "../../../images/About/colleagues/img7.png";
import img8 from "../../../images/About/colleagues/img8.png";
import img9 from "../../../images/About/colleagues/img9.png";
import img10 from "../../../images/About/colleagues/img10.png";
import img11 from "../../../images/About/colleagues/img11.png";
import img12 from "../../../images/About/colleagues/img12.png";
import img13 from "../../../images/About/colleagues/img13.png";
import img14 from "../../../images/About/colleagues/img14.png";
import img15 from "../../../images/About/colleagues/img15.png";
import img16 from "../../../images/About/colleagues/img16.png";
import img17 from "../../../images/About/colleagues/img17.png";
import { OutboundLink } from "gatsby-plugin-google-analytics";

var parse = require("html-react-parser");

class WorkWithUsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      subtitle: this.props.subtitle,
      image: this.props.image
    };
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);

    gsap.set([this.title, this.text1, this.text2, this.button, this.images], {
      y: 50,
      opacity: 0
    });
    gsap.set([this.circle1, this.circle2, this.circle3, this.circle4], {
      opacity: 0
    });
    gsap.set([this.img1, this.img2, this.img3, this.img4], {
      opacity: 0
    });
    gsap.to(this.title, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.text1, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.6,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.text2, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.65,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.button, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.85,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.images, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.65,
      duration: 1,
      ease: "power3"
    });

    var tl_circle = gsap.timeline({ repeat: -1, repeatDelay: 0 });
    tl_circle.to(this.circle1, { opacity: 1, duration: 1.5, repeat: 1, yoyo: true });
    tl_circle.to(this.circle4, { opacity: 1, duration: 1.5, repeat: 1, yoyo: true });
    tl_circle.to(this.circle2, { opacity: 1, duration: 1.5, repeat: 1, yoyo: true });
    tl_circle.to(this.circle3, { opacity: 1, duration: 1.5, repeat: 1, yoyo: true });

    var tl_img = gsap.timeline({ repeat: -1, repeatDelay: 0 });
    tl_img.to(this.img1, { opacity: 1, duration: 1.5, repeat: 1, yoyo: true });
    tl_img.to(this.img2, { opacity: 1, duration: 1.5, repeat: 1, yoyo: true });
    tl_img.to(this.img3, { opacity: 1, duration: 1.5, repeat: 1, yoyo: true });
    tl_img.to(this.img4, { opacity: 1, duration: 1.5, repeat: 1, yoyo: true });
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="workWithUsComponent">
        <div className="workWithUsComponent_cont">
          <div className="workWithUsComponent_cont_leftCont">
            <h2 ref={(ref) => (this.title = ref)}>{parse(intl.formatMessage({ id: "About.WorkWithUs.Title" }))}</h2>
            <p ref={(ref) => (this.text1 = ref)}>{intl.formatMessage({ id: "About.WorkWithUs.Subtitle-1" })}</p>
            <p ref={(ref) => (this.text2 = ref)}>{intl.formatMessage({ id: "About.WorkWithUs.Subtitle-2" })}</p>
            <OutboundLink
              href="https://clever-team.notion.site/clever-team/Careers-Hello-Clever-119c8c68c1c54eb5b858202ef8a1cccf"
              target="_blank">
              <div ref={(ref) => (this.button = ref)} className="button workWithUsComponent_cont_leftCont_button">
                <p>{intl.formatMessage({ id: "About.WorkWithUs.Button" })}</p>
              </div>
            </OutboundLink>
          </div>
          <div ref={(ref) => (this.images = ref)} className="workWithUsComponent_cont_rightCont">
            <div className="workWithUsComponent_cont_rightCont_row">
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img1} />
                <div
                  ref={(ref) => (this.circle1 = ref)}
                  className="workWithUsComponent_cont_rightCont_row_imgCont_circle pink"></div>
              </div>
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img2} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle blue"></div>
              </div>
              <div ref={(ref) => (this.img1 = ref)} className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img3} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle green"></div>
              </div>
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img4} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle blue"></div>
              </div>
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img5} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle grey"></div>
              </div>
            </div>
            <div className="workWithUsComponent_cont_rightCont_row">
              <div ref={(ref) => (this.img2 = ref)} className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img6} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle blue"></div>
              </div>
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img7} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle pink"></div>
              </div>
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img8} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle blue"></div>
              </div>
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img9} />
                <div
                  ref={(ref) => (this.circle2 = ref)}
                  className="workWithUsComponent_cont_rightCont_row_imgCont_circle green"></div>
              </div>
              <div ref={(ref) => (this.img3 = ref)} className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img10} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle blue"></div>
              </div>
            </div>
            <div className="workWithUsComponent_cont_rightCont_row">
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img11} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle green"></div>
              </div>
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img12} />
                <div
                  ref={(ref) => (this.circle3 = ref)}
                  className="workWithUsComponent_cont_rightCont_row_imgCont_circle grey"></div>
              </div>
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img13} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle pink"></div>
              </div>
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img14} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle grey"></div>
              </div>
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img15} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle pink"></div>
              </div>
            </div>
            <div className="workWithUsComponent_cont_rightCont_row">
              <div ref={(ref) => (this.img4 = ref)} className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img16} />
                <div className="workWithUsComponent_cont_rightCont_row_imgCont_circle grey"></div>
              </div>
              <div className="workWithUsComponent_cont_rightCont_row_imgCont">
                <img src={img17} />
                <div
                  ref={(ref) => (this.circle4 = ref)}
                  className="workWithUsComponent_cont_rightCont_row_imgCont_circle green"></div>
              </div>
            </div>
          </div>
          <OutboundLink
            href="https://helloclever.notion.site/Careers-Hello-Clever-119c8c68c1c54eb5b858202ef8a1cccf"
            target="_blank">
            <div ref={(ref) => (this.button = ref)} className="button workWithUsComponent_cont_leftCont_button-mobile">
              <p>{intl.formatMessage({ id: "About.WorkWithUs.Button" })}</p>
            </div>
          </OutboundLink>
        </div>
      </div>
    );
  }
}
export default injectIntl(WorkWithUsComponent);

import React from "react";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import Slider from "react-slick";
import "./PictureSliderComponent.scss";
import image1 from "../../../images/About/slider/slider1.png";
import image2 from "../../../images/About/slider/slider2.png";
import image3 from "../../../images/About/slider/slider3.png";
import image4 from "../../../images/About/slider/slider4.png";

class PictureSliderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.settings = {
      className: "slider variable-width",
      accessibility: false,
      variableWidth: true,
      autoplay: true,
      infinite: true,
      speed: 10000,
      autoplaySpeed: 0,
      cssEase: "linear",
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      pauseOnHover: false,
      focusOnSelect: false,
      lazyLoad: "ondemand"
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="pictureSliderComponent">
        <div className="pictureSliderComponent_cont">
          <Slider {...this.settings}>
            <div className="slideCont">
              <img style={{ width: "525px" }} src={image1} />
              <img style={{ width: "390px" }} src={image2} />
              <img style={{ width: "250px" }} src={image3} />
              <img style={{ width: "390px" }} src={image4} />
            </div>
            <div className="slideCont">
              <img style={{ width: "525px" }} src={image1} />
              <img style={{ width: "390px" }} src={image2} />
              <img style={{ width: "250px" }} src={image3} />
              <img style={{ width: "390px" }} src={image4} />
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
export default PictureSliderComponent;

import * as React from "react";
import Layout from "../components/layout";
import ColorfulCTAComponent from "../containers/Common/ColorfulCTAComponent/ColorfulCTAComponent";
import { StickyContainer } from "react-sticky";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import Hero from "../containers/About/Hero/Hero";
import FunctionsSection from "../containers/Customer/FunctionsSection/FunctionsSection";
import TextComponent from "../containers/About/TextComponent/TextComponent";
import PictureSliderComponent from "../containers/About/PictureSliderComponent/PictureSliderComponent";
import PartnersComponent from "../containers/About/PartnersComponent/PartnersComponent";
import WorkWithUsComponent from "../containers/About/WorkWithUsComponent/WorkWithUsComponent";

// markup
const About = () => {
  const intl = useIntl();
  return (
    <StickyContainer>
      <Layout>
        <Hero />
        <TextComponent />
        <PictureSliderComponent />
        <PartnersComponent />
        <WorkWithUsComponent />
        <ColorfulCTAComponent
          title={intl.formatMessage({ id: "About.SignUp.Title" })}
          subtitle={intl.formatMessage({ id: "About.SignUp.Subtitle" })}
          button={intl.formatMessage({ id: "About.SignUp.Button" })}
          link={"https://helloclever.notion.site/Careers-Hello-Clever-119c8c68c1c54eb5b858202ef8a1cccf"}
        />
      </Layout>
    </StickyContainer>
  );
};

export default About;

import React from "react";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import lottie from "lottie-web";
import lottieSliderData from "../../../lotties/Homepage/slider.json";
import image1 from "../../../images/About/hero1.png";
import image2 from "../../../images/About/hero2.png";
import image3 from "../../../images/About/hero3.png";
import image4 from "../../../images/About/hero4.png";
import image5 from "../../../images/About/hero5.png";

import "./Hero.scss";

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      subtitle: this.props.subtitle,
      image: this.props.image
    };
  }

  componentDidMount() {
    this.setupLotties();
    gsap.registerPlugin(ScrollTrigger);

    gsap.set([this.title, this.image1, this.image2, this.image3, this.image4, this.image5], {
      y: 50,
      opacity: 0
    });
    gsap.to(this.title, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.slider, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.6,
      duration: 0.5,
      ease: "power3"
    });

    gsap.to(this.image1, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.6,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.image2, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.8,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.image4, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 1,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.image3, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 1,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.image5, {
      scrollTrigger: this.title,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 1.2,
      duration: 1,
      ease: "power3"
    });
  }

  setupLotties = () => {
    this.sliderLottie = lottie.loadAnimation({
      container: this.slider,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: lottieSliderData
    });
  };

  render() {
    const { intl } = this.props;
    return (
      <div className="aboutHero">
        <div className="aboutHero_cont">
          <div className="aboutHero_cont_textCont">
            <h1 ref={(ref) => (this.title = ref)}>{intl.formatMessage({ id: "About.Hero.Title" })}</h1>
            <div ref={(ref) => (this.slider = ref)} className="aboutHero_cont_textCont_sliderLottie"></div>
          </div>
          <div className="aboutHero_cont_imageCont">
            <div className="aboutHero_cont_imageCont_column">
              <img
                alt={"aircrex clever people"}
                ref={(ref) => (this.image1 = ref)}
                src={image1}
                className="aboutHero_cont_imageCont_column_image"
              />
            </div>
            <div className="aboutHero_cont_imageCont_column">
              <img
                alt={"aircrex clever people"}
                ref={(ref) => (this.image2 = ref)}
                src={image2}
                className="aboutHero_cont_imageCont_column_image"
              />
              <img
                alt={"aircrex clever people"}
                ref={(ref) => (this.image3 = ref)}
                src={image3}
                className="aboutHero_cont_imageCont_column_image"
              />
            </div>
            <div className="aboutHero_cont_imageCont_column">
              <img
                alt={"aircrex clever people"}
                ref={(ref) => (this.image4 = ref)}
                src={image4}
                className="aboutHero_cont_imageCont_column_image"
              />
              <img
                alt={"aircrex clever people"}
                ref={(ref) => (this.image5 = ref)}
                src={image5}
                className="aboutHero_cont_imageCont_column_image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(Hero);

import React from "react";
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from "react-device-detect";
import { FormattedMessage, Link, useIntl, navigate, injectIntl } from "gatsby-plugin-intl";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import logo1 from "../../../images/About/partners/partner1.svg";
import logo2 from "../../../images/About/partners/partner2.svg";
import logo3 from "../../../images/About/partners/partner3.png";
import logo4 from "../../../images/About/partners/partner4.png";
import logo5 from "../../../images/About/partners/partner5.svg";
import "./PartnersComponent.scss";
var parse = require("html-react-parser");

class PartnersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      subtitle: this.props.subtitle,
      image: this.props.image
    };
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);

    gsap.set([this.title, this.subtitle, this.logos], {
      y: 50,
      opacity: 0
    });
    gsap.set(this.box, {
      width: "20vw",
      opacity: 0
    });
    gsap.to(this.box, {
      scrollTrigger: this.box,
      start: "top 70%",
      width: isDesktop ? "1080px" : "100%",
      opacity: 1,
      delay: 0.4,
      duration: 1,
      ease: "power2"
    });

    gsap.to(this.title, {
      scrollTrigger: this.box,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 0.8,
      duration: 1,
      ease: "power3"
    });

    gsap.to(this.subtitle, {
      scrollTrigger: this.box,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 1,
      duration: 1,
      ease: "power3"
    });
    gsap.to(this.logos, {
      scrollTrigger: this.box,
      start: "top 70%",
      opacity: 1,
      y: 0,
      delay: 1.2,
      duration: 1,
      ease: "power3"
    });
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="partnersComponent">
        <div ref={(ref) => (this.box = ref)} className="partnersComponent_cont">
          <h2 ref={(ref) => (this.title = ref)}>{intl.formatMessage({ id: "About.Partners.Title" })}</h2>
          <p ref={(ref) => (this.subtitle = ref)}>{parse(intl.formatMessage({ id: "About.Partners.Subtitle" }))}</p>
          <div className="partnersComponent_cont_logosCont" ref={(ref) => (this.logos = ref)}>
            <div className="partnersComponent_cont_logosCont_row">
              <img src={logo1} />
              <img src={logo2} />
              <img src={logo3} />
            </div>
            <div className="partnersComponent_cont_logosCont_row">
              <img src={logo4} />
              <img src={logo5} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(PartnersComponent);
